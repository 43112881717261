var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2ca4af2c86d8e1d7bacf63b91ef9896390cab1d1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, BrowserTracing } from '@sentry/nextjs'
// import LogRocket from 'logrocket'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT =
  process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV || process.env.NODE_ENV

init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.25,
  environment: ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['api.testnet.nf.domains', 'api.nf.domains']
    })
  ],
  // beforeSend(event) {
  //   const logRocketSession = LogRocket.sessionURL
  //   if (logRocketSession !== null) {
  //     event.extra = {
  //       ...event.extra,
  //       ['LogRocket Timestamp']: logRocketSession
  //     }
  //   }

  //   return event
  // },
  ignoreErrors: [
    'Operation cancelled',
    'Transaction Request Rejected: The User has rejected the transaction request.',
    'Abort route change. Please ignore this error.',
    'Invalid Input: Invalid Asset',
    'this name/nfd already has a verify request outstanding',
    'Can not open popup window',
    'Insufficient balance',
    'Insufficient available balance',
    'User did not accept terms',
    'The bridge server is not active anymore',
    'already opted in to app',
    'Wallet is not connected',
    'Wallet not connected',
    'The modal has been closed by the user',
    'User did not accept terms',
    'Signing Error: Network mismatch between dApp and Wallet',
    'name is too long. Max length is 32',
    'error in verification of field',
    'Session currently connected',
    'Windows is opened',
    'name must match the regexp',
    'field to verify is in incorrect format',
    'not confirmed after 4 rounds',
    'Error fetching Discord name',
    'this name/nfd already has a verify request outstanding',
    'Transaction Request Rejected',
    'Missing or invalid topic field',
    'AlgoSigner is not available',
    'Exodus is not available',
    'NotAllowedError: Write permission denied',
    'AbortError: The operation was aborted',
    'error initializing client',
    'invalid name',
    'not found',
    'Unknown error',
    `undefined is not an object (evaluating 'window.webkit.messageHandlers')`
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
